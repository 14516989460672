nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%,0,0);
  top: 15%;
  left: -6.5rem;
}
nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #575757;
  transition: all 0.5s ease;
}
nav a:hover {
  margin-right: 20px;
  text-decoration: none;
  color: #35e225;
  transition: all 0.5s ease;
}
nav a.active {
  color: #03b51d;
  transition: all 0.5s ease;
}