* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
::-moz-selection {
  background: #03b51d;
  color: #fafafa;
  text-shadow: none;
}
::selection {
  background: #03b51d;
  color: #fafafa;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  transition: all 0.5s ease;
}
p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}